import './App.css';
import { ContactUs, Footer, Header, HeroSection, Packages, Portfolio, Promotions, Services, Testimonials } from './components';

function App() {
  return (
    <div className="App">
      <Header />
      <HeroSection />
      {/* <OurClients /> */}
      <ContactUs />
      <Services />
      <Portfolio />
      <Packages />
      <Promotions />
      <Testimonials />
      <Footer/>
    </div>
  );
}

export default App;
