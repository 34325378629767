import React from "react";
import PropTypes from 'prop-types';

const ButtonPrimary = (props) => (
    <a href={props.link} className={`${props.rootClass} text-xs sm:text-sm font-medium capitalize rounded-md shadow-md cursor-pointer px-6 sm:px-8 py-[11px] sm:py-[15px]`}>
        {props.label}
    </a>
)

ButtonPrimary.propTypes = {
    rootClass: PropTypes.string,
    label: PropTypes.string,
    link: PropTypes.string
};

ButtonPrimary.defaultProps = {
    rootClass: 'bg-gray-400',
    label: 'Button',
    link: '#'
};

export default React.memo(ButtonPrimary);