import React, { useEffect, useState } from "react";
import { ButtonPrimary } from "../../elements";
import Logo from "../../logo.svg";

const Header = () => {
    const data = {
        menu: [
            { label: 'Home', link: '#' },
            { label: 'Services', link: '#services' },
            { label: 'Portfolio', link: '#portfolio' },
            { label: 'Packages', link: '#packages' },
            { label: 'Testimonial', link: '#testimonial' }
        ],

        button: {
            label: 'Contact Us', link: '#contact'
        }
    };

    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpen, _isOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            setIsScrolled(scrollTop > 100);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`${isScrolled ? 'bg-white' : 'bg-white'} sticky top-0 z-50 w-full flex items-center justify-center shadow-lg py-2 transition-all ease-in-out duration-150`}>
            {/* desktop view */}
            <div className="w-11/12 xl:w-9/12 flex items-center justify-between">
                {/* logo */}
                <a href="/">
                    <div className="w-full max-w-[70px] sm:max-w-[80px] text-white">
                        <img src={Logo} alt='logo' />
                    </div>
                </a>

                {/* actions */}
                <div className="flex items-center space-x-14 xl:space-x-20">
                    {/* nav */}
                    <nav>
                        <ul className={`${isScrolled ? 'text-primary' : 'text-primary'} hidden lg:flex items-center space-x-6 xl:space-x-10 transition-all ease-in-out duration-150`}>
                            {
                                data.menu.map((item, index) => (
                                    <li key={index} className="relative group">
                                        <a href={item.link}>{item.label}</a>
                                        <span className={`${isScrolled ? 'bg-primary' : 'bg-primary'} absolute left-0 bottom-0 w-0 group-hover:w-full h-0.5 transition-all ease-in-out duration-150`}></span>
                                    </li>
                                ))
                            }
                        </ul>
                    </nav>

                    {/* contact us */}
                    <ButtonPrimary label={data.button.label} link={data.button.link} rootClass={`${isScrolled ? 'bg-primary hover:bg-fourth text-secondary' : 'bg-primary hover:bg-fourth text-secondary'} hidden lg:block transition-all ease-in-out duration-150`} />

                    {/* hamburger */}
                    <div onClick={() => _isOpen(!isOpen)} className={`${isScrolled ? 'bg-primary text-primary' : 'bg-primary text-primary'} w-9 h-9 bg-opacity-10 flex lg:hidden items-center justify-center rounded-md p-1 cursor-pointer transition-all ease-in-out duration-150`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-full h-auto">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
                        </svg>
                    </div>
                </div>
            </div>

            {/* mobile view */}
            {
                <div className={`${isOpen ? 'translate-x-0' : '-translate-x-full'} absolute inset-0 w-full h-screen flex flex-col bg-secondary p-4 space-y-4 transition-all ease-in-out duration-300`}>
                    <div className="flex items-center justify-between">
                        {/* logo */}
                        <a href="/">
                            <div onClick={() => _isOpen(false)} className="w-full max-w-[70px] sm:max-w-[80px]">
                                <img src={Logo} alt='logo' />
                            </div>
                        </a>

                        {/* hamburger */}
                        <div onClick={() => _isOpen(false)} className='w-9 h-9 bg-primary text-primary bg-opacity-10 flex items-center justify-center rounded-md p-1 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="full h-auto">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                    </div>
                    <div className="w-full flex-1 flex flex-col items-center justify-center bg-primary bg-opacity-5 rounded-3xl space-y-20">
                        {/* nav */}
                        <nav>
                            <ul className='text-primary text-center text-xl md:text-2xl space-y-8'>
                                {
                                    data.menu.map((item, index) => (
                                        <li key={index} onClick={() => _isOpen(false)} className="relative group">
                                            <a href={item.link}>{item.label}</a>
                                            <span className='absolute left-0 bottom-0 w-0 bg-primary group-hover:w-full h-0.5 transition-all ease-in-out duration-150'></span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </nav>

                        {/* contact us */}
                        <div onClick={() => _isOpen(false)}>
                            <ButtonPrimary label={data.button.label} link={data.button.link} rootClass='bg-primary text-secondary hover:scale-105 transition-all ease-in-out duration-150' />
                        </div>
                    </div>
                </div>
            }
        </header>
    );
};

export default React.memo(Header);