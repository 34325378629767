import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Scrollbar, Autoplay, FreeMode } from 'swiper';
import { ButtonPrimary, ButtonSecondary } from "../../elements";

const Services = () => {
    const data = {
        sectionLabel: 'Our Services',

        heading: 'What We Offer',

        description: 'Tailored solutions crafted by experts to meet your specific business requirements.',

        buttonOne: {
            label: 'Portfolio', link: '#portfolio'
        },

        buttonTwo: {
            label: 'Contact Us', link: '#contact'
        },

        services: [
            {
                label: 'Web Design',
                description: 'Creating visually appealing, user-friendly websites that meet your specific needs and goals.',
                icon: '/assets/images/service-icon-one.png',
                action: 'Learn more',
                link: '#'
            },
            {
                label: 'Website Development',
                description: 'Custom websites and applications with cutting-edge technologies and functionality.',
                icon: '/assets/images/service-icon-four.png',
                action: 'Learn more',
                link: '#'
            },
            {
                label: 'eCommerce',
                description: 'Setting up and optimizing online stores with payment processing for maximum sales.',
                icon: '/assets/images/service-icon-two.png',
                action: 'Learn more',
                link: '#'
            },
            {
                label: 'Video Production',
                description: 'Crafting engaging videos that tell your brand story and capture your audience`s attention.',
                icon: '/assets/images/service-icon-three.png',
                action: 'Learn more',
                link: '#'
            },
            // ============
            {
                label: 'Digital Marketing',
                description: 'Boosting your online visibility and driving targeted traffic to your website.',
                icon: '/assets/images/service-icon-five.png',
                action: 'Learn more',
                link: '#'
            },
            {
                label: 'Responsive Design',
                description: 'Ensuring an optimal viewing and interaction experience on all devices.',
                icon: '/assets/images/service-icon-four.png',
                action: 'Learn more',
                link: '#'
            },
            {
                label: 'Hosting',
                description: 'Providing secure and reliable website hosting for optimal website performance.',
                icon: '/assets/images/service-icon-six.png',
                action: 'Learn more',
                link: '#'
            },
            {
                label: 'UI/UX Design',
                description: 'Creating interactive interfaces that enhance user engagement and satisfaction.',
                icon: '/assets/images/service-icon-eight.png',
                action: 'Learn more',
                link: '#'
            },
            {
                label: 'Website Maintenance',
                description: 'Regularly checking your website for issues and mistakes and keeping your website up to date.',
                icon: '/assets/images/service-icon-seven.png',
                action: 'Learn more',
                link: '#'
            }
        ]
    };
    return (
        <div id="services" className="w-full flex items-center justify-center py-10 sm:py-20 bg-cover bg-center bg-no-repeat" style={{ backgroundImage: 'url(/assets/images/background-one.png)' }}>
            <div className="w-11/12 xl:w-9/12 h-max flex flex-col space-y-8 sm:space-y-16">
                <div className="space-y-4 text-secondary text-center sm:text-left">
                    <h2>{data.sectionLabel}</h2>
                    <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <h3 className="text-3xl sm:text-4xl md:text-5xl font-medium capitalize">{data.heading}</h3>
                        <p className="text-xs sm:text-sm md:text-base flex items-center font-light">
                            {data.description}
                        </p>
                    </div>
                </div>
                {/* <div className="2xl:w-full grid grid-cols-[repeat(auto-fit,_minmax(220px,_1fr))] gap-4 sm:gap-8">
                    {
                        data.services.map((service, index) => (
                            <div key={index} className="bg-secondary rounded-3xl space-y-3 sm:space-y-5 p-6 sm:p-8">
                                <img src={service.icon} alt="web design" className="w-14 h-auto" />
                                <h4 className="text-xl sm:text-2xl font-normal">{service.label}</h4>
                                <p className="text-gray-700 text-xs sm:text-sm font-light">
                                    {service.description}
                                </p>
                                <a href={service.link} className="inline-flex items-center text-primary text-xs sm:text-sm font-medium space-x-2 group">
                                    <span>{service.action}</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 group-hover:translate-x-1 transition-all ease-in-out duration-150">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                                    </svg>
                                </a>
                            </div>
                        ))
                    }
                </div> */}
                <div>
                    <Swiper
                        modules={[Scrollbar, Autoplay, FreeMode]}
                        scrollbar={{
                            hide: true,
                        }}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 0,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 32,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 32,
                            },
                            1280: {
                                slidesPerView: 4,
                                spaceBetween: 32,
                            },
                            1700: {
                                slidesPerView: 5,
                                spaceBetween: 32,
                            },
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        freeMode={true}
                        className="cursor-grab"
                    >
                        {
                            data.services.map((service, index) => (
                                <SwiperSlide key={index} className='!h-auto'>
                                    <div className="h-full bg-secondary rounded-3xl space-y-3 sm:space-y-5 p-6 sm:p-8">
                                        <img src={service.icon} alt="web design" className="w-14 h-auto" />
                                        <h4 className="text-xl sm:text-2xl font-normal">{service.label}</h4>
                                        <p className="text-gray-700 text-xs sm:text-sm font-light">
                                            {service.description}
                                        </p>
                                        {/* <a href={service.link} className="inline-flex items-center text-primary text-xs sm:text-sm font-medium space-x-2 group">
                                            <span>{service.action}</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 group-hover:translate-x-1 transition-all ease-in-out duration-150">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                                            </svg>
                                        </a> */}
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
                <div className="flex items-center justify-center space-x-7">
                    <ButtonPrimary label={data.buttonOne.label} link={data.buttonOne.link} rootClass='bg-secondary hover:bg-fourth text-primary hover:text-secondary transition-all ease-in-out duration-150' />
                    <ButtonSecondary label={data.buttonTwo.label} link={data.buttonTwo.link} rootClass='border-secondary text-secondary hover:text-primary bg-transparent hover:bg-secondary transition-all ease-in-out duration-150' />
                </div>
            </div>
        </div>
    );
};

export default React.memo(Services);