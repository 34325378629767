import React from "react";
import PropTypes from 'prop-types';

const OurClients = (props) => {
    const images = [
        { label: 'Cybex', src: '/assets/images/cybex.png' },
        { label: 'Cybex', src: '/assets/images/cybex.png' },
        { label: 'Cybex', src: '/assets/images/cybex.png' },
        { label: 'Cybex', src: '/assets/images/cybex.png' }
    ];

    return (
        <div className={`${props.rootClass} w-full flex flex-col items-center justify-center`}>
            <div className="w-11/12 xl:w-9/12 space-y-8">
                <h2 className="text-xl sm:text-2xl font-semibold">Our Clients</h2>
                <div className="grid grid-cols-[repeat(auto-fit,_minmax(150px,_1fr))] gap-8">
                    {
                        images.map((image, index) => (
                            <div key={index} className="flex items-center justify-center">
                                <img src={image.src} alt={image.label} className='w-full max-w-[120px] sm:max-w-[180px] h-auto' />
                            </div>
                        ))
                    }
                </div>
                <div className="w-full h-px bg-gray-200"></div>
            </div>
        </div>
    );
};

OurClients.propTypes = {
    rootClass: PropTypes.string
};

OurClients.defaultProps = {
    rootClass: ''
};

export default React.memo(OurClients);