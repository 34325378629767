import React from "react";
import PropTypes from 'prop-types';

const ButtonSecondary = (props) => (
    <a href={props.link} className={`${props.rootClass} text-xs sm:text-sm font-medium capitalize border rounded-md shadow-md cursor-pointer px-6 sm:px-8 py-2.5 sm:py-3.5`}>
        {props.label}
    </a>
)

ButtonSecondary.propTypes = {
    rootClass: PropTypes.string,
    label: PropTypes.string,
    link: PropTypes.string
};

ButtonSecondary.defaultProps = {
    rootClass: 'border-black',
    label: 'Button',
    link: '#'
};

export default React.memo(ButtonSecondary);