import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";
import { QuotesSvg } from "../../assets/svgs";

const Testimonials = () => {
    const data = {
        sectionLabel: 'Testimonials',

        heading: 'What Our Client Says.',

        description: 'Our clients send us bunch of smiles with our services and we love them',

        testimonials: [
            {
                name: 'Andrew Grant',
                place: 'Chicago',
                image: '/assets/images/person-one.jpg',
                review: 'A world-class and innovative website creation company! Prodigy Web Studios entail a work-oriented team of professionals who create and deliver exceptional results; high conversions and sales, a wide customer base, and other objectives are achieved without delay.',
                numberOfStars: 5
            },
            {
                name: 'John Vendor',
                place: 'United State',
                image: '/assets/images/person-two.jpg',
                review: 'They have expert web app developers with experience. I am glad that my target audience is considering my brand and enjoying the benefits. As a business merchant, it has been a great experience working with Prodigy Web Studios.',
                numberOfStars: 4
            },
            {
                name: 'Sara Khan',
                place: 'UK',
                image: '/assets/images/person-three.jpg',
                review: 'In addition to the solid technical expertise shown by PXL Forge, its developers demonstrated a profound understanding of laboratory software specifics and integrations.',
                numberOfStars: 5
            },
            {
                name: 'Vesa Suontama',
                place: 'London',
                image: '/assets/images/person-four.jpg',
                review: 'We engaged ScienceSoft as our development partner. We recommend them as a trustworthy partner with skilled technical specialists on board.',
                numberOfStars: 4
            }
        ]
    };

    return (
        <div id="testimonial" className="relative w-full flex items-center justify-center">
            <div className="w-11/12 xl:w-9/12 relative z-10 pt-10 sm:pt-20">
                <div className="text-secondary space-y-4 text-center sm:text-left">
                    <h2>{data.sectionLabel}</h2>
                    <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <h3 className="text-2xl sm:text-3xl font-normal capitalize">{data.heading}</h3>
                        <p className="text-xs sm:text-sm md:text-base flex items-center font-light">
                            {data.description}
                        </p>
                    </div>
                </div>
                <div className="w-full h-px sm:h-0.5 bg-secondary mt-10"></div>

                {/* swiper */}
                <div className="relative bg-tertiary pb-10 mt-8 sm:mt-16">
                    <Swiper
                        breakpoints={{
                            0: {
                              slidesPerView: 1,
                            },
                            768: {
                              slidesPerView: 2,
                            },
                          }}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper"
                    >
                        {
                            data.testimonials.map((testimonial, index) => (
                                <SwiperSlide key={index} className="p-7 sm:p-14 pb-24">
                                    <div>
                                        <div className="flex items-center space-x-6">
                                            <div className="w-20 h-20 rounded-full overflow-hidden bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${testimonial.image})` }}></div>
                                            <div className="flex-1 text-secondary">
                                                <h3 className="text-lg font-semibold">{testimonial.name}</h3>
                                                <p className="text-sm font-light">{testimonial.place}</p>
                                            </div>
                                        </div>
                                        <p className="text-secondary text-xs sm:text-sm mt-8 sm:mt-10">
                                            {testimonial.review}
                                        </p>
                                        <div className="flex items-center space-x-px mt-8 sm:mt-16">
                                            {
                                                [...Array(testimonial.numberOfStars)].map((star, index) => (
                                                    <div key={index} className="w-4 h-4 text-yellow-500">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-full h-auto">
                                                            <path fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clipRule="evenodd" />
                                                        </svg>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>

                    <span className="absolute bottom-0 sm:-bottom-6 right-8 sm:right-16 w-24 sm:w-40 h-24 sm:h-40">
                        <QuotesSvg className="w-full h-auto" />
                    </span>
                </div>
            </div>

            {/* background */}
            <div className="w-full h-full absolute">
                <div className="w-full h-4/5 bg-cover bg-center bg-no-repeat" style={{ backgroundImage: 'url(/assets/images/background-three.png)' }}></div>
                <div className="w-full h-1/5 bg-transparent"></div>
            </div>
        </div>
    );
};

export default React.memo(Testimonials);