import React from "react";
import { BlogBgOneSvg, LineDotPatternSvg } from "../../assets/svgs";
import { ButtonPrimary } from "../../elements";

const Promotions = () => {
    const data = {
        promotionOne: {
            label: 'Promotion',
            heading: 'Promote your business with impactful websites',
            description: 'Do you want to view your brand as a successful business option? Or are you aiming to discover more potential opportunities for your initiative? Prodigy Web Studios is a leading website design company in the USA that develops interactive sites for businesses, enterprises, and entrepreneurs.',
            image: '/assets/images/promotion-image-one.png',

            buttonOne: {
                label: 'Services', link: '#services'
            },

            buttonTwo: {
                label: 'Contact Us', link: '#contact'
            },
        },

        promotionTwo: {
            image: '/assets/images/promotion-image-two.png',

            list: [
                {
                    heading: 'LANDING PAGE DESIGN',
                    description: 'A well-designed landing page converts more web traffic because they provide information about a specific offer or item. It includes customer-centric information, engaging visuals, and a responsive design.'
                },
                {
                    heading: 'CORPORATE WEBSITE',
                    description: 'Establish a strong identity for your corporation with a corporate website that can speak for itself. We design sites that cultivate your online presence.'
                },
                {
                    heading: 'E-COMMERCE WEBSITE',
                    description: 'Online trade depends highly on e-commerce websites. Having your site helps to understand your customers. You can analyze their buying patterns through website analytics and opt for better approaches in the future.'
                }
            ]
        }
    };

    return (
        <div id="promotion" className="relative w-full flex items-center justify-center py-10 sm:py-20">
            <div className="relative z-10 w-11/12 xl:w-9/12 space-y-16 lg:space-y-0">
                {/* promotion one */}
                <div className="w-full grid grid-cols-1 lg:grid-cols-2">
                    {/* text area */}
                    <div className="flex flex-col justify-center space-y-3 sm:space-y-8 lg:pr-16">
                        <h2 className="text-primary">{data.promotionOne.label}</h2>
                        <h3 className="text-2xl sm:text-3xl font-normal capitalize">{data.promotionOne.heading}</h3>
                        <p className="text-xs sm:text-sm md:text-base flex items-center text-gray-600">
                            {data.promotionOne.description}
                        </p>
                        {/* actions */}
                        <div className="flex items-center space-x-7">
                            <ButtonPrimary label={data.promotionOne.buttonOne.label} link={data.promotionOne.buttonOne.link} rootClass='bg-primary hover:bg-fourth text-secondary transition-all ease-in-out duration-150' />
                            <ButtonPrimary label={data.promotionOne.buttonTwo.label} link={data.promotionOne.buttonTwo.link} rootClass='bg-tertiary hover:bg-fourth text-secondary transition-all ease-in-out duration-150' />
                        </div>
                    </div>
                    {/* image area */}
                    <div className="relative flex items-center justify-center">
                        <BlogBgOneSvg className="w-full h-auto drop-shadow-[10px_20px_10px_rgba(41,97,244,0.2)] sm:drop-shadow-[20px_40px_20px_rgba(41,97,244,0.2)]" />
                        <img src={data.promotionOne.image} alt={data.promotionOne.heading} className="absolute w-full h-auto" />
                    </div>
                </div>
                {/* promotion two */}
                <div className="w-full flex flex-col-reverse lg:grid grid-cols-2">
                    {/* image area */}
                    <div className="relative flex items-center justify-center">
                        <BlogBgOneSvg className="w-full h-auto drop-shadow-[10px_20px_10px_rgba(41,97,244,0.2)] sm:drop-shadow-[20px_40px_20px_rgba(41,97,244,0.2)]" />
                        <img src={data.promotionTwo.image} alt="promote your business" className="absolute w-full h-auto" />
                    </div>
                    {/* text area */}
                    <div className="flex flex-col justify-center space-y-5 sm:space-y-10 lg:pl-16">
                        {
                            data.promotionTwo.list.map((item, index) => (
                                <div key={index} className="space-y-0 sm:space-y-3">
                                    <h3 className="text-2xl sm:text-2xl font-normal capitalize">{item.heading}</h3>
                                    <p className="text-xs sm:text-sm md:text-base flex items-center text-gray-600">
                                        {item.description}
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            {/* pattern */}
            <div className="hidden xl:block absolute top-1/4 right-0 w-3/12 h-auto">
                <LineDotPatternSvg className="w-full h-auto" />
            </div>
        </div>
    );
};

export default React.memo(Promotions);