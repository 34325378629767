import React from "react";
import { ButtonPrimary } from "../../elements";

const Packages = () => {
    const data = {
        sectionlabel: 'Our Packages',

        heading: 'What We Offer',

        description: 'Within your means, Spend your money wisely by selecting a website package that caters to your needs!',

        plans: [
            {
                label: 'Basic',
                price: 1499,
                description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                features: ['5 Pages of Website', 'Cross-browser compatibility', 'Multiple Devices Compatible', 'Dynamic Layouts', 'Social Media Integration', 'Complete Deployment'],
                action: 'Get Started',
                actionLink: '#contact'
            },
            {
                label: 'Brand Startup',
                price: 2499,
                description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                features: ['10 to 15 Pages of Website', 'Cross-browser compatibility', 'Multiple Devices Compatible', 'Dynamic Layouts', 'Built on Wordpress or Php', 'Payment Integration', 'Product Upload (300)', 'Social Media Integration', 'Complete Deployment', 'FREE Google friendly sitemap', 'Post Deployment Support & Maintenance'],
                action: 'Get Started',
                actionLink: '#contact'
            },
            {
                label: 'Corporate Pack',
                price: 5499,
                description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                features: ['Unlimited Pages of Website', 'Cross-browser compatibility', 'Multiple Devices Compatible', 'Dynamic Layouts', 'Built on Wordpress or Php', 'Payment Integration', 'Product Upload (1000)', 'Inventory Management System', 'Customer Login Area', 'Admin Panel', 'Social Media Integration', 'Complete Deployment', 'FREE Google friendly sitemap', 'Post Deployment Support & Maintenance'],
                action: 'Get Started',
                actionLink: '#contact'
            }
        ],

        buttonOne: {
            label: 'Services', link: '#services'
        },

        buttonTwo: {
            label: 'Contact Us', link: '#contact'
        },
    }
    return (
        <div id="packages" className="w-full flex items-center justify-center py-10 sm:py-20 bg-cover bg-center bg-no-repeat" style={{ backgroundImage: 'url(/assets/images/background-two.png)' }}>
            <div className="w-11/12 xl:w-9/12 h-max flex flex-col space-y-8 sm:space-y-16">
                <div className="space-y-4 text-center">
                    <h2 className="text-primary">{data.sectionlabel}</h2>
                    <h3 className="text-3xl sm:text-4xl md:text-5xl font-medium capitalize">{data.heading}</h3>
                    <p className="text-xs sm:text-sm md:text-base w-full sm:w-3/5 lg:w-1/2 font-light mx-auto text-gray-500">
                        {data.description}
                    </p>
                </div>
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8">
                    {
                        data.plans.map((plan, index) => (
                            <div key={index} className="flex flex-col bg-secondary hover:bg-primary rounded-3xl shadow-lg space-y-5 p-8 transition-all ease-in-out duration-300 group">
                                <h4 className="text-primary group-hover:text-secondary text-lg sm:text-xl font-light transition-all ease-in-out duration-300">{plan.label}</h4>
                                <h5 className="text-primary group-hover:text-secondary text-4xl sm:text-5xl font-bold transition-all ease-in-out duration-300">$<span>{plan.price}</span></h5>
                                {/* <p className="text-gray-500 group-hover:text-secondary text-xs transition-all ease-in-out duration-300">{plan.description}</p> */}
                                <ul className="flex-1 text-gray-500 group-hover:text-secondary text-sm space-y-2 transition-all ease-in-out duration-300">
                                    {
                                        plan.features.map((feature, index) => (
                                            <li key={index} className="flex items-start space-x-2">
                                                <svg className="w-2.5 h-2.5 translate-y-1 text-primary group-hover:text-secondary transition-all ease-in-out duration-300" fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
                                                <span>{feature}</span>
                                            </li>
                                        ))
                                    }
                                </ul>
                                <a href={plan.actionLink} className="w-full text-sm sm:text-base bg-tertiary group-hover:bg-secondary text-secondary group-hover:text-tertiary flex items-center justify-center rounded-md space-x-4 py-2 sm:py-2.5 !mt-10 hover:scale-105 shadow-md transition-all ease-in-out duration-300">
                                    <span>{plan.action}</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg>
                                </a>
                            </div>
                        ))
                    }
                </div>
                {/* actions */}
                <div className="flex items-center justify-center space-x-7">
                    <ButtonPrimary label={data.buttonOne.label} link={data.buttonOne.link} rootClass='bg-primary hover:bg-fourth text-secondary transition-all ease-in-out duration-150' />
                    <ButtonPrimary label={data.buttonTwo.label} link={data.buttonTwo.link} rootClass='bg-tertiary hover:bg-fourth text-secondary transition-all ease-in-out duration-150' />
                </div>
            </div>
        </div>
    );
};

export default React.memo(Packages);