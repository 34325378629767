import React from "react";
import Logo from "../../logo.svg";
import { AmericanExpressCardSvg, CloseLockSvg, DiscoverCardSvg, FacebookSvg, InstagramSvg, JcbCardSvg, LinkedinSvg, MasterCardSvg, TwitterSvg, VisaCardSvg } from "../../assets/svgs";

const Footer = () => {
    const data = {
        about: 'Catering to your business transition needs with a systematic and strategic plan that guarantees a significant return on investment. Catering to your business transition needs with a systematic and strategic plan that guarantees a significant return on investment.',

        copyRight: '2023 PXL Forge. All Rights Reserved',

        services: {
            title: 'Services',
            actions: [
                { label: 'Website Design', link: '#services' },
                { label: 'eCommerce', link: '#services' },
                { label: 'Website Development', link: '#services' },
                { label: 'Video Production', link: '#services' },
            ]
        },

        webStudios: {
            title: 'Company',
            actions: [
                { label: 'Promotion', link: '#promotion' },
                { label: 'Services', link: '#services' },
                { label: 'Portfolio', link: '#portfolio' },
                { label: 'Testimonial', link: '#testimonial' },
                { label: 'Packages', link: '#packages' },
            ]
        },

        paymentMethod: {
            title: 'Payment Method',
            list: [
                { label: 'visa', image: VisaCardSvg },
                { label: 'master', image: MasterCardSvg },
                { label: 'discover', image: DiscoverCardSvg },
                { label: 'american express', image: AmericanExpressCardSvg },
                { label: 'jcb', image: JcbCardSvg },
            ],
            email: 'sales@pxlforge.com',
            phone: '832-699-1200',
            address: '18410 Bison Back Dr, Humble, TX 77346',
            location: 'https://goo.gl/maps/F4uzcdsid4fFfPQP6'
        },

        socialMedia: [
            { label: 'facebook', icon: FacebookSvg, link: 'https://www.facebook.com/' },
            { label: 'twitter', icon: TwitterSvg, link: 'https://www.twitter.com/' },
            { label: 'instagram', icon: InstagramSvg, link: 'https://www.instagram.com/' },
            { label: 'linkedin', icon: LinkedinSvg, link: 'https://www.linkedin.com/' }
        ]
    };
    return (
        <div className="w-full flex flex-col items-center justify-center">
            {/* top */}
            <div className="w-11/12 xl:w-9/12 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-5 gap-5 py-10 sm:py-20">
                {/* logo & about */}
                <div className="col-span-2 sm:col-span-1 lg:col-span-2 space-y-5 sm:space-y-10">
                    <a href="/">
                        <div className="w-full max-w-[70px] sm:max-w-[80px]">
                            <img src={Logo} alt='logo' />
                        </div>
                    </a>
                    <p className="text-gray-500 text-xs sm:text-sm md:text-base sm:pr-20">
                        {data.about}
                    </p>
                </div>

                {/* services */}
                <div className="space-y-5 sm:space-y-10">
                    <h2 className="text-xl sm:text-2xl capitalize">{data.services.title}</h2>
                    <ul className="text-gray-500 text-xs sm:text-sm md:text-base capitalize space-y-2 sm:space-y-5">
                        {
                            data.services.actions.map((action, index) => (
                                <li key={index} className="relative w-max hover:text-primary group">
                                    <a href={action.link}>{action.label}</a>
                                    <span className="absolute left-0 bottom-0 bg-primary w-0 group-hover:w-full h-0.5 transition-all ease-in-out duration-150"></span>
                                </li>
                            ))
                        }
                    </ul>
                </div>

                {/* webStudios */}
                <div className="space-y-5 sm:space-y-10">
                    <h2 className="text-xl sm:text-2xl capitalize">{data.webStudios.title}</h2>
                    <ul className="text-gray-500 text-xs sm:text-sm md:text-base capitalize space-y-2 sm:space-y-5">
                        {
                            data.webStudios.actions.map((action, index) => (
                                <li key={index} className="relative w-max hover:text-primary group">
                                    <a href={action.link}>{action.label}</a>
                                    <span className="absolute left-0 bottom-0 bg-primary w-0 group-hover:w-full h-0.5 transition-all ease-in-out duration-150"></span>
                                </li>
                            ))
                        }
                    </ul>
                </div>

                {/* payment methods */}
                <div className="space-y-5 sm:space-y-10">
                    <h2 className="text-xl sm:text-2xl capitalize">{data.paymentMethod.title}</h2>
                    <div className="relative w-max flex items-center border-2 border-dotted space-x-3 p-2">
                        {
                            data.paymentMethod.list.map((card, index) => (
                                <div key={index} className="w-6 h-6 flex items-center justify-center">
                                    <card.image className="w-full h-auto" />
                                </div>
                            ))
                        }
                        <span className="absolute -top-1.5 -left-1 bg-white text-[8px] italic flex items-center px-1"><CloseLockSvg className="w-3 h-3 mr-0.5" />Secure Payment By</span>
                    </div>
                    <div className="space-y-1">
                        <div>
                            <h2 className="text-tertiary text-base sm:text-lg capitalize">Email address</h2>
                            <a href={`mailto:${data.paymentMethod.email}`} className="text-gray-500 text-xs sm:text-sm">{data.paymentMethod.email}</a>
                        </div>
                        <div>
                            <h2 className="text-tertiary text-base sm:text-lg capitalize">Phone number</h2>
                            <a href={`tel:${data.paymentMethod.phone}`} className="text-gray-500 text-xs sm:text-sm">{data.paymentMethod.phone}</a>
                        </div>
                        <div>
                            <h2 className="text-tertiary text-base sm:text-lg capitalize">Address</h2>
                            <a href={data.paymentMethod.location} target="blank" className="text-gray-500 text-xs sm:text-sm">{data.paymentMethod.address}</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* bottom */}
            <div className="w-full bg-primary flex items-center justify-center py-5">
                <div className="w-11/12 xl:w-9/12 flex items-center justify-center">
                    <p className="text-white text-xs sm:text-sm md:text-base capitalize">&copy; {data.copyRight}</p>
                    {/* <div className="flex items-center space-x-3 sm:space-x-6">
                        {
                            data.socialMedia.map((item, index) => (
                                <a key={index} href={item.link} target="blank" className="w-4 sm:w-5 h-4 sm:h-5 text-white opacity-40 hover:opacity-100 transition-all ease-in-out duration-150 group">
                                    <item.icon className="h-full w-auto group-hover:drop-shadow-[0_0_8px_rgba(255,255,255)] transition-all ease-in-out duration-150" />
                                </a>
                            ))
                        }
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default React.memo(Footer);