import React from "react";
import { FacebookSvg, InstagramSvg, LinkedinSvg, TwitterSvg } from "../../assets/svgs";
import { ButtonPrimary, ButtonSecondary } from "../../elements";

const HeroSection = () => {
    const data = {
        lightText: 'Dive',

        heading: 'Into Our World Of Pixels',

        description: 'Crafting captivating, engaging, and inspiring digital experiences.',

        buttonOne: {
            label: 'Services', link: '#services'
        },

        buttonTwo: {
            label: 'Contact Us', link: '#contact'
        },

        score: '9.8',

        image: { label: 'hero banner', src: '/assets/images/hero-image.png' },

        socialMedia: [
            { label: 'facebook', icon: FacebookSvg, link: 'https://www.facebook.com/' },
            { label: 'twitter', icon: TwitterSvg, link: 'https://www.twitter.com/' },
            { label: 'instagram', icon: InstagramSvg, link: 'https://www.instagram.com/' },
            { label: 'linkedin', icon: LinkedinSvg, link: 'https://www.linkedin.com/' }
        ]
    }

    return (
        <div className="relative w-full flex items-center justify-center pt-12 sm:pt-20 pb-0 sm:pb-24 lg:pb-0 bg-cover bg-left md:bg-bottom bg-no-repeat overflow-hidden" style={{ backgroundImage: 'url(/assets/images/hero-section-bg.png)' }}>
            <div className="relative z-20 w-11/12 xl:w-9/12 grid grid-cols-1 md:grid-cols-2 gap-10">
                {/* text area */}
                <div className="relative">
                    <div className="relative z-10 xl:pr-28">
                        <h1 className="text-secondary text-3xl sm:text-5xl font-bold leading-tight">
                            <span className="font-light">{data.lightText}</span> {data.heading}
                        </h1>
                        <p className="text-secondary text-xs sm:text-base mt-3 sm:mt-6 xl:mt-8">
                            {data.description}
                        </p>

                        {/* actions */}
                        <div className="flex items-center space-x-7 mt-8 xl:mt-10">
                            <ButtonPrimary label={data.buttonOne.label} link={data.buttonOne.link} rootClass='bg-secondary hover:bg-fourth text-primary hover:text-secondary transition-all ease-in-out duration-150' />
                            <ButtonSecondary label={data.buttonTwo.label} link={data.buttonTwo.link} rootClass='border-secondary text-secondary hover:text-primary bg-transparent hover:bg-secondary transition-all ease-in-out duration-150' />
                        </div>

                        {/* rattings */}
                        <div className="mt-5 sm:mt-10">
                            <div>
                                <p className="text-secondary text-[10px]">Score {data.score} out of 10</p>
                                <div className="flex items-center space-x-px">
                                    {
                                        [...Array(5)].map((s, index) => (
                                            <div key={index} className="w-4 h-4 bg-secondary text-primary rounded-sm p-px">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-full h-auto">
                                                    <path fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* animate pattern */}
                    <div className="absolute -top-32 -left-40 w-[120%] text-secondary opacity-[0.07]">
                        <svg className="w-full h-auto" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 288 288">
                            <linearGradient id="PSgrad_0" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                <stop offset="0%" stopColor="rgb(95,54,152)" stopOpacity="1" />
                                <stop offset="100%" stopColor="rgb(247,109,138)" stopOpacity="1" />
                            </linearGradient>
                            <path fill="#FCF9EF">
                                <animate repeatCount="indefinite" attributeName="d" dur="5s"
                                    values="M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45 c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2 c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7 c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z; M51,171.3c-6.1-17.7-15.3-17.2-20.7-32c-8-21.9,0.7-54.6,20.7-67.1c19.5-12.3,32.8,5.5,67.7-3.4C145.2,62,145,49.9,173,43.4 c12-2.8,41.4-9.6,60.2,6.6c19,16.4,16.7,47.5,16,57.7c-1.7,22.8-10.3,25.5-9.4,46.4c1,22.5,11.2,25.8,9.1,42.6 c-2.2,17.6-16.3,37.5-33.5,40.8c-22,4.1-29.4-22.4-54.9-22.6c-31-0.2-40.8,39-68.3,35.7c-17.3-2-32.2-19.8-37.3-34.8 C48.9,198.6,57.8,191,51,171.3z; M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45 c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2 c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7 c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z	" />
                            </path>
                        </svg>
                    </div>
                </div>

                {/* image area */}
                <div className="w-full aspect-[1.01/1] flex items-center justify-center">
                    <img
                        src={data.image.src}
                        alt={data.image.label}
                        className="w-full h-auto"
                    />
                </div>
            </div>

            {/* social media */}
            {/* <div className="absolute top-0 left-16 z-30 w-10 h-full hidden xl:flex items-center justify-center">
                <div className="flex flex-col items-center space-y-6">
                    {
                        data.socialMedia.map((item, index) => (
                            <a key={index} href={item.link} target="blank" className="w-5 h-5 text-white opacity-40 hover:opacity-100 transition-all ease-in-out duration-150 group">
                                <item.icon className="h-full w-auto group-hover:drop-shadow-[0_0_8px_rgba(255,255,255)] transition-all ease-in-out duration-150" />
                            </a>
                        ))
                    }
                </div>
            </div> */}

            {/* animate lines */}
            {/* left line */}
            <div className="absolute top-0 left-[9%] h-full hidden xl:flex justify-center">
                <div className="w-px h-full bg-secondary"></div>
                <div className="absolute -top-8 h-full animate-slideInDown">
                    <div className="w-8 h-8 border-[5px] border-secondary backdrop-blur-md rounded-full"></div>
                </div>
            </div>

            {/* right line */}
            <div className="absolute top-0 right-[9%] h-full hidden xl:flex justify-center">
                <div className="w-px h-full bg-secondary"></div>
                <div className="absolute -top-8 h-full animate-slideInUp">
                    <div className="w-8 h-8 bg-white rounded-full"></div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(HeroSection);