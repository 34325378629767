import React, { useState } from "react";
import { Modal } from "..";
import { ButtonPrimary } from "../../elements";

const Portfolio = () => {
    const data = {
        sectionLabel: 'Our Portfolio',
        heading: <>Looking for design inspiration? <br/> Browse our portfolio to see our creative grind.</>,
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy.',
        
        buttonOne: {
            label: 'Services', link: '#services'
        },

        buttonTwo: {
            label: 'Contact Us', link: '#contact'
        },
    }

    const tabs = ['Website Design', 'Logo', 'E-Commerce', 'Corporate Branding', 'Video Production'];

    const [selected, _selected] = useState('Website Design');

    const tabsData = {
        'Website Design': {
            label: 'Website Design',
            type: 'image',
            images : [
                '/assets/images/website-design-01.png',
                '/assets/images/website-design-02.png',
                '/assets/images/website-design-03.png',
                '/assets/images/website-design-04.png',
                '/assets/images/website-design-05.png',
                '/assets/images/website-design-06.png',
                '/assets/images/website-design-07.png',
                '/assets/images/website-design-08.png',
                '/assets/images/website-design-09.png',
                '/assets/images/website-design-10.png',
                '/assets/images/website-design-11.png',
                '/assets/images/website-design-12.png'
            ]
        },
        'Logo': {
            label: 'Logo',
            type: 'image',
            images : [
                '/assets/images/logo-design-01.png',
                '/assets/images/logo-design-02.png',
                '/assets/images/logo-design-03.png',
                '/assets/images/logo-design-04.png',
                '/assets/images/logo-design-05.png',
                '/assets/images/logo-design-06.png',
                '/assets/images/logo-design-07.png',
                '/assets/images/logo-design-08.png',
                '/assets/images/logo-design-09.png',
                '/assets/images/logo-design-10.png',
                '/assets/images/logo-design-11.png',
                '/assets/images/logo-design-12.png',
            ]
        },
        'E-Commerce' : {
            label: 'E-Commerce',
            type: 'image',
            images : [
                '/assets/images/ecommerce-design-01.png',
                '/assets/images/ecommerce-design-02.png',
                '/assets/images/ecommerce-design-03.png',
                '/assets/images/ecommerce-design-04.png',
                '/assets/images/ecommerce-design-05.png',
                '/assets/images/ecommerce-design-06.png',
                '/assets/images/ecommerce-design-07.png',
                '/assets/images/ecommerce-design-08.png',
                '/assets/images/ecommerce-design-09.png',
                '/assets/images/ecommerce-design-10.png',
                '/assets/images/ecommerce-design-11.png',
                '/assets/images/ecommerce-design-12.png'
            ]
        },
        'Corporate Branding': {
            label: 'Corporate Branding',
            type: 'image',
            images : [
                '/assets/images/corporate-design-01.png',
                '/assets/images/corporate-design-02.png',
                '/assets/images/corporate-design-03.png',
                '/assets/images/corporate-design-04.png',
                '/assets/images/corporate-design-05.png',
                '/assets/images/corporate-design-06.png',
                '/assets/images/corporate-design-07.png',
                '/assets/images/corporate-design-08.png',
                '/assets/images/corporate-design-09.png',
                '/assets/images/corporate-design-10.png',
                '/assets/images/corporate-design-11.png',
                '/assets/images/corporate-design-12.png'
            ]
        },
        'Video Production': {
            label: 'Video Production',
            type: 'video',
            images : [
                'B_zZelk7kUQ',
                'f6wlpzPRQ_4',
                'tKHj0wwoyS0',
                'UYhbCOCiw7o'
            ]
        }
    };

    // function getYouTubeThumbnail(link) {
    //     const regex = /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/videos\?flow=grid&\S+))([\w-]{11})(?:$|\?|\s)/;
    //     const videoId = link.match(regex)[1];
    //     console.log(videoId);
    //     return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    // };

    const [showModal, _showModal] = useState(false);
    const [modalData, _modalData] = useState({
        type: '',
        label: '',
        source: ''
    });

    return (
        <div id="portfolio" className="w-full flex items-center justify-center py-10 sm:py-20">
            <div className="w-11/12 xl:w-9/12 h-max flex flex-col space-y-8 sm:space-y-16">
                <div className="space-y-4 text-center sm:text-left">
                    <h2>{data.sectionLabel}</h2>
                    <div className="w-full grid grid-cols-1 sm:grid-cols-1 gap-4">
                        <h3 className="text-2xl sm:text-3xl font-normal capitalize">{data.heading}</h3>
                        {/* <p className="text-xs sm:text-sm md:text-base flex items-center font-light text-gray-500">
                            {data.description}
                        </p> */}
                    </div>
                </div>
                <div className="grid grid-cols-5">
                    {
                        tabs.map((tab, index) => (
                            <div
                                key={index}
                                onClick={() => _selected(tab)}
                                className={`${selected === tab ? 'bg-primary text-secondary' : 'hover:bg-gray-100 text-gray-400 hover:text-primary'} text-center text-[10px] sm:text-sm sm:font-medium border-t border-gray-200 rounded-b-lg cursor-pointer px-1 sm:px-2 py-2 sm:py-4 transition-all ease-in-out duration-150`}
                            >
                                {tab}
                            </div>
                        ))
                    }
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-3 sm:gap-6">
                    {
                        tabsData[selected].images.map((image, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    _showModal(true);
                                    _modalData(old => ({ ...old, source: image, label: tabsData[selected].label, type: tabsData[selected].type }));
                                }}
                                className="aspect-square bg-cover bg-top bg-no-repeat rounded-3xl sm:rounded-[40px] shadow-lg cursor-pointer"
                                style={{ backgroundImage: `url(${tabsData[selected].type === 'video' ? `https://img.youtube.com/vi/${image}/0.jpg` : image})` }}
                            ></div>
                        ))
                    }
                </div>
                {/* actions */}
                <div className="flex items-center justify-center space-x-7">
                    <ButtonPrimary label={data.buttonOne.label} link={data.buttonOne.link} rootClass='bg-primary hover:bg-fourth text-secondary transition-all ease-in-out duration-150' />
                    <ButtonPrimary label={data.buttonTwo.label} link={data.buttonTwo.link} rootClass='bg-tertiary hover:bg-fourth text-secondary transition-all ease-in-out duration-150' />
                </div>
            </div>

            {
                <Modal
                    isOpen={showModal}
                    onClose={() => _showModal(false)}
                    modalData={modalData}
                />
            }
        </div>
    );
};

export default React.memo(Portfolio);