import React from "react";
import { ButtonPrimary } from "../../elements";

const ContactUs = () => {
    const data = {
        heading: 'Boost your brand with creative web design.',

        description: 'As a Texas-based Digital Web Agency, we specialize in designing visually captivating and user-friendly websites that elevate your online presence. With a decade of industry experience, we take pride in our unwavering dedication to quality, professionalism, and ensuring utmost customer satisfaction.',

        images: [
            { label: 'Cybex', src: '/assets/images/cybex.png' },
            { label: 'Cybex', src: '/assets/images/cybex.png' },
            { label: 'Cybex', src: '/assets/images/cybex.png' },
            { label: 'Cybex', src: '/assets/images/cybex.png' }
        ],

        buttonOne: {
            label: 'Services', link: '#services'
        },

        buttonTwo: {
            label: 'Portfolio', link: '#portfolio'
        },
    };

    return (
        <div id="contact" className="w-full flex items-center justify-center py-10 sm:py-20">
            <div className="w-11/12 xl:w-9/12 flex flex-col lg:grid lg:grid-cols-12 gap-10 lg:gap-0">
                {/* form area */}
                <div className="h-[620px] lg:h-[620px] lg:col-span-5 xl:col-span-4 relative order-2 lg:order-none">
                    <div className="w-full sm:w-4/5 lg:w-full h-3/5 bg-fourth mx-auto"></div>
                    <div className="w-full sm:w-4/5 lg:w-full h-2/5 bg-primary -translate-x-full mx-auto"></div>
                    {/* form */}
                    <div className="absolute inset-0 top-10 max-w-[384px] w-full h-max mx-auto">
                        <img src="/assets/images/iphone-x-screen-mockup.png" alt="screen mockup" className="w-full h-auto" />
                        <form className="absolute inset-0 w-8/12 h-full flex items-center p-4 mx-auto">
                            <div className="space-y-2 sm:space-y-4">
                                <h3 className="text-2xl font-bold text-center">Get in touch</h3>
                                <p className="text-xs text-gray-500 font-light text-center">We are looking forward to start a project with you!</p>
                                {/* name */}
                                <div className="bg-primary flex items-center rounded-full overflow-hidden space-x-2 p-1">
                                    <div className="w-8 h-8 bg-secondary text-primary rounded-full p-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-full h-auto">
                                            <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                    <input type='text' placeholder="Name*" className="appearance-none w-full flex-1 bg-transparent text-xs sm:text-sm text-secondary placeholder:text-secondary outline-none" />
                                </div>

                                {/* email */}
                                <div className="bg-primary flex items-center rounded-full overflow-hidden space-x-2 p-1">
                                    <div className="w-8 h-8 bg-secondary text-primary rounded-full p-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-full h-auto">
                                            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                        </svg>
                                    </div>
                                    <input type='email' placeholder="Email*" className="appearance-none w-full flex-1 bg-transparent text-xs sm:text-sm text-secondary placeholder:text-secondary outline-none" />
                                </div>

                                {/* phone */}
                                <div className="bg-primary flex items-center rounded-full overflow-hidden space-x-2 p-1">
                                    <div className="w-8 h-8 bg-secondary text-primary rounded-full p-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-full h-auto">
                                            <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                    <input type='tel' placeholder="Phone*" className="appearance-none w-full flex-1 bg-transparent text-xs sm:text-sm text-secondary placeholder:text-secondary outline-none" />
                                </div>

                                {/* message */}
                                <div className="relative">
                                    <textarea placeholder="Message" rows={5} className="appearance-none w-full bg-primary text-secondary placeholder:text-secondary text-xs sm:text-sm outline-none p-2 pl-6 rounded-2xl" />
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-3 h-3 absolute top-2.5 left-2 text-secondary">
                                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                                    </svg>
                                </div>

                                {/* submit */}
                                <input
                                    type="submit"
                                    value="Send message"
                                    className="appearance-none w-full bg-primary hover:bg-fourth text-xs sm:text-sm text-secondary font-medium px-1 py-2.5 rounded-full cursor-pointer outline-none transition-all ease-in-out duration-150"
                                />
                            </div>
                        </form>
                    </div>
                </div>
                {/* text area */}
                <div className="lg:col-span-7 xl:col-span-8 flex flex-col justify-center space-y-6 sm:space-y-12 lg:p-16">
                    <div className="space-y-4 sm:space-y-7">
                        <h2 className="text-2xl sm:text-3xl font-normal capitalize">{data.heading}</h2>
                        <p className="text-xs sm:text-sm md:text-base flex items-center text-gray-600">
                            {data.description}
                        </p>
                    </div>
                    {/* <div className="w-full h-px bg-gray-200"></div> */}
                    {/* <div className="space-y-4 sm:space-y-7">
                        <h3 className="text-lg sm:text-xl md:text-2xl font-semibold">Trusted by</h3>
                        <div className="grid grid-cols-[repeat(auto-fill,_minmax(80px,_1fr))] gap-2 sm:gap-5 xl:gap-8">
                            {
                                data.images.map((image, index) => (
                                    <div key={index} className="w-full">
                                        <img src={image.src} alt={image.label} className='w-full h-auto' />
                                    </div>
                                ))
                            }
                        </div>
                    </div> */}
                    {/* actions */}
                    <div className="flex items-center space-x-7">
                        <ButtonPrimary label={data.buttonOne.label} link={data.buttonOne.link} rootClass='bg-primary hover:bg-fourth text-secondary transition-all ease-in-out duration-150' />
                        <ButtonPrimary label={data.buttonTwo.label} link={data.buttonTwo.link} rootClass='bg-tertiary hover:bg-fourth text-secondary transition-all ease-in-out duration-150' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ContactUs);